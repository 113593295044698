<template>
    <div class="col-12 g">
        <div class="card card-body">
            <div class="col-12">
                <div class="col-12 col-lg-4">
                    <span>البحث في الارشيف</span>
                    <input type="text" placeholder="ابحث هنا..." class="form-control" v-model="q" @keyup="page = 0; getArchive();">
                </div> 
                <div class="g col-12">
                    <button @click="getArchive()" class="btn btn-sm btn-primary"><i class="fa fa-refresh"></i> تحديث</button> &nbsp;
                    <button class="btn btn-sm btn-warning" @click="print()"><i class="fa fa-print"></i> طباعة الصفحة</button> &nbsp;
                    <button class="btn btn-sm btn-warning" @click="selectprint=true;" v-if="!selectprint"><i class="fa fa-check-square"></i> تحديد وطباعة</button>
                    <button class="btn btn-sm btn-success" @click="selectprint=false;printSlectedc()" v-if="selectprint"><i class="fa fa-check-square"></i> طباعة المحدد الآن</button> &nbsp;
                    <div class="col-12 d-block d-lg-none g"></div>
                    <select v-model="type" style="border: 1px solid #777; border-radius: 5px" @change="getArchive()">
                        <option value="all">كل الرسائل</option>
                        <option value="students">نظام تحضير الطلاب</option>
                        <option value="results">ارسال الشهادات</option>
                        <option value="teachers">نظام غياب المعلمين</option>
                        <option value="committees">اعداد اللجان</option>
                        <option value="lessons">تحضير الحصص</option>
                        <option value="mentors">النماذج والكوشف</option>
                    </select>&nbsp; 
                    <div class="col-12 d-block d-lg-none g"></div>
                    <button class="btn btn-sm btn-outline-danger" v-b-modal.resend @click="resendx()"><i class="fa fa-refresh"></i> اعادة ارسال الرسائل الفاشلة</button>
                </div>
            </div>
            <img :src="require('@/assets/images/loading.svg')" alt="" width="50" v-if="loading">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-striped table-bordered" id="headerTable">
                    <thead>
                        <th v-if="selectprint">اختيار</th>
                        <th>
                            الجوال
                        </th>
                        <th>
                            التاريخ
                        </th>
                        <th>
                            الحالة
                        </th>
                        <th>
                            طريقة الارسال
                        </th>
                        <th>
                            الرسالة
                        </th>
                    </thead>
                    <tbody>
                        <template v-for="message in messages">
                        <tr v-if="selected.includes(message._id) && onlyselected || !onlyselected" :key="message._id">
                            <td v-if="selectprint">
                                <div class="form-check">
                                  <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input" v-model="selected" :value="message._id">
                                    اختيار
                                  </label>
                                </div>
                            </td>
                            <td>
                                {{ message.phone }}
                            </td>
                            <td>
                                {{ message.date }}
                            </td>
                            <td>
                                <span v-if="message.status == 0" class='btn btn-sm btn-warning'>
                                    <i class="fa fa-clock-o"></i>
                                    جاري الارسال
                                </span>
                                <span v-if="message.status == 1" class='btn btn-sm btn-success'>
                                    <i class="fa fa-check"></i>
                                    تم الارسال
                                </span>
                                <span v-if="message.status == 2" class='btn btn-sm btn-danger'>
                                    <i class="fa fa-times"></i>
                                    فشل الارسال
                                </span>
                                <span v-if="message.status == 4" class='btn btn-sm btn-primary'>
                                    <i class="fa fa-refresh"></i>
                                    تم اعادة ارسالهم
                                </span>
                                <span v-if="message.status == 3" class='btn btn-sm btn-info'>
                                    <i class="fa fa-whatsapp"></i>
                                    مرسل بواسطة اداة الواتساب
                                </span>
                                <small v-if="message.status == 2">
                                    <br>
                                    {{ message.response }}
                                </small>
                            </td>
                            <td>
                                <span v-if="message.method == 'sms'"><i class="fa fa-mobile"></i> SMS <span v-if="message.settings && message.settings != 'undefined' && message.settings != undefined">({{ message.settings.sms_portal }})</span></span>
                                <span v-if="message.method == 'smart-whats'"><i class="fa fa-whatsapp"></i> الواتس الذكي</span>
                                <span v-if="message.method == 'whatsapp'"><i class="fa fa-whatsapp"></i> اداة الواتساب</span>
                            </td>
                            <td class="noexchangedate">
                                <button class="btn btn-sm btn-secondary vv" v-if="!views[message._id]" @click="views[message._id] = !views[message._id]; refreshMessages()"><i class='fa fa-eye'></i> عرض الرسالة</button>
                                <textarea v-if="views[message._id]" class="hidemeprint noexchangedate" readonly v-model="message.message" style="border:none;height:80px; width: 100%"></textarea>
                                <span class="dnone viewmeprint">{{ message.message }}</span>
                            </td>
                        </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <div class="col-12">
                <button class="btn btn-danger" @click="page--; getArchive()" v-if=" page > 0 && !loading">الصفحة السابقة <i class="fa fa-arrow-right"></i></button>&nbsp;
                <button class="btn btn-primary" @click="page++; getArchive()" v-if="messages.length > 98 && !loading">الصفحة التالية <i class="fa fa-arrow-left"></i></button>
            </div>
        </div>
        <b-modal id="resend" title="اعادة ارسال الرسائل الفاشلة" hide-footer>
            <div class="col-12 text-center g" v-if="resend_loading">
                <img :src="require('@/assets/images/loading.svg')" style="width: 100px;">
            </div>
            <div v-if="!resend_loading">
                <div class="alert alert-success g text-center" v-if="resend_stats == 0">
                    <span class="text-dark">لا يوجد رسائل فاشلة اليوم.</span>
                </div>
                <div class="alert alert-warning g text-center" v-if="resend_stats > 0">
                    <span class="text-dark">
                        هناك {{ resend_stats == 100 ? '+100' : resend_stats }} رسالة فاشلة اليوم.
                    </span>
                </div>
                <div class="row" v-if="resend_stats > 0">
                    <div class="col-12">
                        اعادة ارسالهم عن طريق:
                    </div>
                    <div class="col-12 col-lg-4 g">
                        <button class="btn btn-success btn-block"  @click="send('whatsapp')">
                            <i class="fa fa-whatsapp"></i>
                            واتس تلقائي
                        </button>
                    </div>
                    <div class="col-12 col-lg-4 g">
                        <button class="btn btn-success btn-block" @click="send('wa')">
                            <i class="fa fa-whatsapp"></i>
                            اداة المتصفح
                        </button>
                    </div>
                    <div class="col-12 col-lg-4 g">
                        <button class="btn btn-primary btn-block"  @click="send('sms')">
                            <i class="fa fa-mobile"></i>
                            SMS
                        </button>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            loading: true,
            loading2: true,
            user: JSON.parse(localStorage.getItem('user')),
            messages: [],
            q: "",
            page: 0,
            type: window.location.href?.split("?type=")[1] ?? 'all',
            resend_loading: false,
            resend_stats: 0,
            interval: null,
            views: {},
            selectprint: false,
            selected: [],
            onlyselected: false
        }
    },
    beforeDestroy(){
        if(this.interval){
            clearInterval(this.interval)
        }
    },
    created(){
        if(!checkPer("archive")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        g.getArchive()
        g.interval = setInterval(() => {
            g.getArchive(true)
        }, 2000);
    },
    methods: {
        refreshMessages(){
            this.messages = JSON.parse(JSON.stringify(this.messages))
        },
        resendx(){
            var g = this;
            g.resend_loading = true;
            $.post(api + '/user/messages/resend-stats', {
                jwt: this.user.jwt
            }).then(function(r){
                g.resend_loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    g.resend_stats = r.response
                }
            }).fail(function(){
                g.resend_loading = false;
                alert("حدث خطأ", 200);
            })
        },
        printSlectedc(){
            var g = this;
            g.onlyselected = true;
            g.print()
            setTimeout(() => {
                g.onlyselected = false;
            }, 500);
        },
        print(){
            $(".vv").each(function(){
                $(this).click()
            })
            setTimeout(() => {
            var divToPrint=document.getElementById("headerTable");
            var newWin= window.open("");
            newWin.document.write(divToPrint.outerHTML + `<style>
                body{
                    direction: rtl;
                    text-align: right
                }
                thead{
                    background: #eee;
                }
                table, td, th {
                border: 1px solid;
                }
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                table {
                width: 100%;
                border-collapse: collapse;
                }
                .btn-danger{
                    background: #FD8A8A;
                }
                .btn-success{
                    background: #97DECE;
                }
                .btn-info{
                    background: #82AAE3;
                }
                .btn{
                    width: 100% !important;
                    padding-left: 2px;
                    padding-right: 2px;
                }
                .hidemeprint{
                    display:none;
                }
                .viewmeprint{
                    white-space: pre-line;
                    width: 200px !important
                }
                </style>`);
            newWin.document.close();
            newWin.print();     
            }, 100);
        },
        getArchive(noload = false){
            var g = this;
            if(!noload){
                g.loading = true;
            }
            $.post(api + '/user/messages/archive', {
                jwt: this.user.jwt,
                q: this.q,
                type: this.type,
                page: this.page,
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.messages = r.response.map(function(x){
                        x.view_message = false
                        return x
                    })
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        send(method){
            var g = this;
            $.post(api + '/user/messages/get-resend', {
                jwt: this.user.jwt
            }).then(function(rx){
                if(method == 'wa'){
                    $("#sendmodalwaarray").val(JSON.stringify(rx.response))
                    $("#sendmodalwatype").val("results")
                    $("#sendWAModal").click()
                }else{
                    if(!confirm(`متأكد من اعادة ارسال ${rx.response.length} رسالة؟`)){return false;}
                    $.post(api + '/user/messages/send-arr', {
                        jwt: g.user.jwt,
                        arr: JSON.stringify(rx.response),
                        method: method
                    }).then(function(r){
                        g.loaded = true;
                        if(r.status != 100){
                            alert(r.response, 200)
                        }else{
                            g.getArchive()
                            $('[class="close"]').click()
                            setTimeout(() => {
                                $('[class="close"]').click()
                            }, 100);
                        }
                    }).fail(function(){
                        alert("حدث خطأ", 200)
                    })
                }
            }).fail(function(){
                    alert("حدث خطأ.", 200)
                })
        }
    }
}
</script>
<style scoped>
.table,th,td,tr{
    white-space: nowrap !important;
}
</style>